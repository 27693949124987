<template>
<div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="0"
      img-width="100%"
      img-height="38%"
      background="#e1e1e1"
      no-wrap
      ref="Carousel"
      class="desktoponly"
    >
      <b-carousel-slide :img-src="require('@/assets/images/slider0.png')">
            <button class="btn startbutton neg-margin" type="button" @click="next()">Indulhat a kalkuláció!</button>
      </b-carousel-slide>
      <b-carousel-slide img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">Válassza ki <span class="font-weight-light">a ház típusát!</span></h2>
              </b-row>
              <b-row>
                  <b-col col lg=1>
                  </b-col>
                  <b-col col lg=10>
                      <b-row>
                        <b-col col lg="4" class="pointer pb-3">
                                <img :src="require('@/assets/images/Felnyeregteto_gray.png')" v-if="houses[0].show == 0" @click="changeData(0)">
                                <img :src="require('@/assets/images/Felnyeregteto.png')" v-if="houses[0].show == 1">
                            <p class="text-uppercase font-italic">FÉLNYEREGTETŐ</p>
                        </b-col>
                        <b-col col lg="4" class="pointer pb-3">
                                <img :src="require('@/assets/images/Kontyolt nyeregteto_gray.png')"  v-if="houses[1].show == 0" @click="changeData(1)">
                                <img :src="require('@/assets/images/Kontyolt nyeregteto.png')" v-if="houses[1].show == 1">
                            <p class="text-uppercase font-italic">KONTYOLT NYEREGTETŐ</p>
                        </b-col>
                        <b-col col lg="4" class="pointer pb-3">
                                <img :src="require('@/assets/images/L nyeregteto_gray.png')"  v-if="houses[2].show == 0" @click="changeData(2)">
                                <img :src="require('@/assets/images/L nyeregteto.png')" v-if="houses[2].show == 1">
                            <p class="text-uppercase font-italic">L NYEREGTETŐ</p>
                        </b-col>
                        <b-col col lg="4" class="pointer">
                                <img :src="require('@/assets/images/Nyeregteto_gray.png')"  v-if="houses[3].show == 0" @click="changeData(3)">
                                <img :src="require('@/assets/images/Nyeregteto.png')" v-if="houses[3].show == 1">
                            <p class="text-uppercase font-italic">NYEREGTETŐ</p>
                        </b-col>
                        <b-col col lg="4" class="pointer">
                                <img :src="require('@/assets/images/Osszetett kontyolt nyeregteto_gray.png')"  v-if="houses[4].show == 0" @click="changeData(4)">
                                <img :src="require('@/assets/images/Osszetett kontyolt nyeregteto.png')" v-if="houses[4].show == 1">
                            <p class="text-uppercase font-italic">Összetett kontyolt nyeregtető</p>
                        </b-col>
                        <b-col col lg="4" class="pointer">
                                <img :src="require('@/assets/images/Osszetett nyeregteto_gray.png')"  v-if="houses[5].show == 0" @click="changeData(5)">
                                <img :src="require('@/assets/images/Osszetett nyeregteto.png')" v-if="houses[5].show == 1">
                            <p class="text-uppercase font-italic">ÖSSZETETT NYEREGTETŐ</p>
                        </b-col>
                     </b-row>
                  </b-col>
                  <b-col col lg=1>
                    <div v-if="selected" class="arrow-next pointer" @click="next()"><i class="arrow right"></i></div>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col class="text-center">
                      <img :src="require('@/assets/images/slide/1.png')">
                  </b-col>
              </b-row>
          </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">TETŐHOSSZÚSÁG (méter)</h2>
              </b-row>
              <b-row>
                  <b-col col lg=1>
                      <div class="arrow-next pointer" @click="prev()"><i class="arrow left"></i></div>
                  </b-col>
                  <b-col col lg=10>
                      <b-row>
                        <b-col col lg=6>
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_felnyeregteto_szines.png')" v-if="houses[0].show == 1">
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_kontyolt_nyeregteto_szines.png')" v-if="houses[1].show == 1"> 
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_l_nyeregteto_szines.png')" v-if="houses[2].show == 1"> 
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_nyeregteto_szines.png')" v-if="houses[3].show == 1"> 
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_osszetett_kontyolt_nyeregteto_szines.png')" v-if="houses[4].show == 1"> 
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_osszetett_nyeregteto_szines.png')" v-if="houses[5].show == 1"> 
                        </b-col>
                        <b-col col lg=6>
                            <b-form-input class="budafer-input" type="number" placeholder="A: (méter)" ref="housea"  v-if="forms > 0" min="1" max="100" v-model="result.inputs[0]" :required="forms > 0 ? true : false"></b-form-input>
                            <b-form-input class="budafer-input" type="number" placeholder="B: (méter)" ref="houseb"  v-if="forms > 1" min="1" max="100" v-model="result.inputs[1]" :required="forms > 1 ? true : false"></b-form-input>
                            <b-form-input class="budafer-input" type="number" placeholder="C: (méter)" ref="housec"  v-if="forms > 2" min="1" max="100" v-model="result.inputs[2]" :required="forms > 2 ? true : false"></b-form-input>
                            <b-form-input class="budafer-input" type="number" placeholder="D: (méter)" ref="housed"  v-if="forms > 3" min="1" max="100" v-model="result.inputs[3]" :required="forms > 3 ? true : false"></b-form-input>
                            <b-form-input class="budafer-input" type="number" placeholder="E: (méter)" ref="housee" v-if="forms > 4" min="1" max="100" v-model="result.inputs[4]" :required="forms > 4 ? true : false"></b-form-input>
                        </b-col>
                     </b-row>
                  </b-col>
                  <b-col col lg=1>
                    <div v-if="selected" class="arrow-next pointer" @click="next()"><i class="arrow right"></i></div>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col class="text-center pt-3">
                      <img :src="require('@/assets/images/slide/2.png')">
                  </b-col>
              </b-row>
          </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">Tetőmagasság (méter)</h2>
              </b-row>
              <b-row>
                  <b-col col lg=1>
                      <div class="arrow-next pointer" @click="prev()"><i class="arrow left"></i></div>
                  </b-col>
                  <b-col col lg=10>
                      <b-row>
                        <b-col col lg=6>
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_22_tetomagassag.png')">
                        </b-col>
                        <b-col col lg=6>
                            <b-form-input class="budafer-input" type="number"  ref="roufa" placeholder="A: (méter)" v-model="result.inputs[5]" step="0.1" min="1" max="100" required></b-form-input>
            <b-form-input class="budafer-input" type="number" placeholder="B: (méter)" ref="roufb" v-model="result.inputs[6]" step="0.1" min="1" max="100" required></b-form-input>
                        </b-col>
                     </b-row>
                  </b-col>
                  <b-col col lg=1>
                    <div v-if="selected" class="arrow-next pointer" @click="next()"><i class="arrow right"></i></div>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col class="text-center pt-3">
                      <img :src="require('@/assets/images/slide/3.png')">
                  </b-col>
              </b-row>
          </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">Ereszrendszer Tulajdonságai</h2>
              </b-row>
              <b-row>
                  <b-col col lg=1>
                      <div class="arrow-next pointer" @click="prev()"><i class="arrow left"></i></div>
                  </b-col>
                  <b-col col lg=10>
                      <b-row>
                        <b-col col lg=6>
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_23_eresztulajdonsag.png')">
                        </b-col>
                        <b-col col lg=6>
                            <b-form-select class="budafer-input" v-model="result.selected[1]" :options="options[1]" required></b-form-select>  
                        </b-col>
                     </b-row>
                  </b-col>
                  <b-col col lg=1>
                    <div v-if="selected" class="arrow-next pointer" @click="next()"><i class="arrow right"></i></div>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col class="text-center pt-3">
                      <img :src="require('@/assets/images/slide/4.png')">
                  </b-col>
              </b-row>
          </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">KÖNYÖK TÍPUSA</h2>
              </b-row>
              <b-row>
                  <b-col col lg=1>
                      <div class="arrow-next pointer" @click="prev()"><i class="arrow left"></i></div>
                  </b-col>
                  <b-col col lg=10>
                      <b-row>
                        <b-col col lg=6>
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_24_konyoktipusa_konyok_fekete.png')">
                        </b-col>
                        <b-col col lg=6>
                            <b-form-select class="budafer-input" v-model.lazy="result.selected[2]" :options="options[2]" required></b-form-select>  
                        </b-col>
                     </b-row>
                  </b-col>
                  <b-col col lg=1>
                    <div v-if="selected" class="arrow-next pointer" @click="next()"><i class="arrow right"></i></div>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col class="text-center pt-3">
                      <img :src="require('@/assets/images/slide/5.png')">
                  </b-col>
              </b-row>
          </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">BILINCS MÉRETE</h2>
              </b-row>
              <b-row>
                  <b-col col lg=1>
                      <div class="arrow-next pointer" @click="prev()"><i class="arrow left"></i></div>
                  </b-col>
                  <b-col col lg=10>
                      <b-row>
                        <b-col col lg=6>
                           <img class="w-75" src="@/assets/images/ereszkalkulator_25_bilics_merete_rovid_fekete_v2.png">
                        </b-col>
                        <b-col col lg=6>
                            <b-form-select class="budafer-input" v-model="result.selected[3]" :options="options[3]" required></b-form-select>  
                            <br><em>Szárhossz= Falvastagság + szigetelés + ráhagyás</em>                        
                        </b-col>
                     </b-row>
                  </b-col>
                  <b-col col lg=1>
                    <div v-if="selected" class="arrow-next pointer" @click="next()"><i class="arrow right"></i></div>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col class="text-center pt-3">
                      <img :src="require('@/assets/images/slide/6.png')">
                  </b-col>
              </b-row>
          </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">KIVITEL</h2>
              </b-row>
              <b-row>
                  <b-col col lg=1>
                      <div class="arrow-next pointer" @click="prev()"><i class="arrow left"></i></div>
                  </b-col>
                  <b-col col lg=10>
                      <b-row>
                        <b-col col lg=6>
                           <img class="w-75" src="@/assets/images/ereszkalkulator_26_minoseg_fekete.png">
                        </b-col>
                        <b-col col lg=6>
                            <b-form-select class="budafer-input" v-model="result.selected[4]" :options="options[4]" required></b-form-select>
                        </b-col>
                     </b-row>
                  </b-col>
                  <b-col col lg=1>
                    <div v-if="selected" class="arrow-next pointer" @click="next()"><i class="arrow right"></i></div>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col class="text-center pt-3">
                      <img :src="require('@/assets/images/slide/7.png')">
                  </b-col>
              </b-row>
          </b-container>
      </b-carousel-slide>
      <b-carousel-slide img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">SZÍN</h2>
              </b-row>
              <b-row>
                  <b-col col lg=1>
                      <div class="arrow-next pointer" @click="prev()"><i class="arrow left"></i></div>
                  </b-col>
                  <b-col col lg=10>
                      <b-row>
                        <b-col col lg=6>
                            <img class="w-75" src="@/assets/images/colors.png">
                        </b-col>
                        <b-col col lg=6>
                            <b-form-select class="budafer-input" v-model="result.selected[5]" :options="options[5]" required></b-form-select>  
                        </b-col>
                     </b-row>
                  </b-col>
                  <b-col col lg=1>
                    <div v-if="selected" class="arrow-next pointer" @click="next()"><i class="arrow right"></i></div>
                  </b-col>
              </b-row>
              <b-row>
                  <b-col class="text-center pt-3">
                      <img :src="require('@/assets/images/slide/8.png')">
                  </b-col>
              </b-row>
          </b-container>
      </b-carousel-slide>
      <b-carousel-slide class="last-step" img-blank>
          <b-container>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">A kalkuláció eredménye</h2>
              </b-row>
              <b-row>
                 <b-row>
          <b-col col lg="12">
               <p class="text-uppercase">A kalkulátor az alábbi paraméterek alapján kalkulált:</p>
          </b-col>
      </b-row>
      <b-row class="table-responsive">
        <table class="table table-bordered text-center">
            <thead>
                <tr>
                <th scope="col" colspan="3">Tetőméretek</th>
                <th scope="col" colspan="3">Tetőmagasság</th>
                <th scope="col">Rendszer</th>
                <th scope="col">Bilincs méret</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>A</td>
                    <td>{{result.inputs[0]}}</td>
                    <td>m</td>
                    <td>A</td>
                    <td>{{result.inputs[5]}}</td>
                    <td>m</td>
                    <td><span class="td-yellow">{{result.selected[1]}}</span></td>
                    <td><span class="td-yellow">{{result.selected[3]}} mm</span></td>
                </tr>
                 <tr>
                    <td>B</td>
                    <td>{{result.inputs[1]}}</td>
                    <td>m</td>
                    <td>B</td>
                    <td>{{result.inputs[6]}}</td>
                    <td>m</td>
                    <th scope="row">Anyagvastagság</th>
                    <th scope="row">Szín és Minőség</th>
                </tr> 
                 <tr>
                    <td>C</td>
                    <td>{{result.inputs[2]}}</td>
                    <td>m</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><span class="td-yellow">{{result.selected[0]}} mm</span></td>
                    <td><span class="td-yellow">{{result.selected[5]}}</span></td>
                </tr> 
                 <tr>
                    <td>D</td>
                    <td>{{result.inputs[3]}}</td>
                    <td>m</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <th scope="row">Könyök</th>
                    <td><span class="td-yellow">{{result.selected[4]}}</span></td>
                </tr> 
                 <tr>
                    <td>E</td>
                    <td>{{result.inputs[4]}}</td>
                    <td>m</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><span class="td-yellow">{{result.selected[2]}}</span></td>
                    <td></td>
                </tr> 
            </tbody>
        </table>
      </b-row>
        <b-col col lg="3">
        </b-col>
        <b-col col lg="6" class="table-responsive">
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                    <th scope="col">Megnevezés</th>
                    <th scope="col">Mennyiség</th>
                    <th scope="col">Mértékegység</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Csatorna</td>
                        <td>{{calculated.csatorna}}</td>
                        <td>m</td>
                    </tr>
                    <tr>
                        <td>Csatornatartó</td>
                        <td>{{calculated.kampo}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Zárt vég</td>
                        <td>{{calculated.zart_veg}}</td>
                        <td>db</td>
                    </tr>
                    <tr>
                        <td>Szeglet</td>
                        <td>{{calculated.sarok}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Zárt vég kifolyóval</td>
                        <td>{{calculated.kifolyo}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Betorkolló</td>
                        <td>{{calculated.betorkollo}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Lefolyó</td>
                        <td>{{calculated.lefolyo}}</td>
                        <td>m</td>
                    </tr>
                     <tr>
                        <td>Könyök</td>
                        <td>{{calculated.konyok}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Bilincs</td>
                        <td>{{calculated.bilincs}}</td>
                        <td>db</td>
                    </tr>
                </tbody>
            </table>
        </b-col>
    </b-row>
              <b-row>
                    <b-col col lg="3"></b-col>
                  <b-col class="text-center">
                    <button @click="prev()" class="bf-button-blue-2" type="button">Módosítás</button>
                </b-col>
                <b-col class="text-center">
                    <button v-b-modal.modal-1 class="bf-button-red" type="button">Ajánlatot kérek</button>
                  </b-col>
                    <b-col col lg="3"></b-col>
              </b-row>
              <b-row>
                   <b-col col lg="4"></b-col>
                    <b-col class="text-center info-box" l col lg="4">
                       <p>Elakadt, segítségre van szüksége?<br>Vegye fel velünk a kapcsolatot!</p>
                       <hr>
                       <p><a href="mail:info@budafer.hu">info@budafer.hu</a> • 06-1-99-888-99</p>
                    </b-col>
                     <b-col col lg="4"></b-col>
              </b-row>
               <b-modal ref="contact-modal" id="modal-1" hide-footer title="Ajánlatkérés">
                <div class="bf-contact">
                    <form id="newproductform" v-on:submit.prevent="submitProduct">
                        <input class="textfield" type="text" name="name" placeholder="Név:" v-model="form.name" required=""><br>
                        <input class="textfield" type="text" name="company" placeholder="Cégnév:" v-model="form.company" required=""><br>
                        <input class="textfield" type="email" name="email" placeholder="Email Cím:" v-model="form.email" required=""><br>
                        <input class="textfield" type="tel" id="phone" name="phone" v-model="form.phone" placeholder="+36301234567" title="+36301234567, 06301234567" pattern="^\+?(\d{10,11})$" required=""><br>
                        <textarea class="textfield" name="message" v-model="form.message"></textarea><br>
                        <input name="calculated" type="hidden"  :value="JSON.stringify(calculated)">
                        <input type="checkbox" name="transport" v-model="form.transport"> Szállítást kérek<br>
                        <input type="checkbox" name="newsletter" v-model="form.newsletter"> Feliratkozom a hírlevélre.<br>
                        <input type="checkbox" required=""> Elolvastam és elfogadom az <a href="//budafer.hu/adatkezelesi-tajekoztato" target="_blank">adatvédelmi nyilatkozatot</a>.<br>
                        <button class="bf-button-yellow" type="submit">Üzenet elküldése</button>
                    </form>
                </div>
            </b-modal>
          </b-container>
      </b-carousel-slide>
    </b-carousel>

<b-form ref="form" class="mobileonly">
    <b-container class="mb-10">
      <b-row>
       <b-col col lg="4" class="houses mt-3">
         <h5 class="font-weight-bold text-uppercase mb-5">1. válasszon háztípust</h5>
         <b-row>
                        <b-col col lg="4" class="pointer pb-3">
                                <img :src="require('@/assets/images/Felnyeregteto_gray.png')" v-if="houses[0].show == 0" @click="changeData(0)">
                                <img :src="require('@/assets/images/Felnyeregteto.png')" v-if="houses[0].show == 1">
                            <p class="text-uppercase font-italic">FÉLNYEREGTETŐ</p>
                        </b-col>
                        <b-col col lg="4" class="pointer pb-3">
                                <img :src="require('@/assets/images/Kontyolt nyeregteto_gray.png')"  v-if="houses[1].show == 0" @click="changeData(1)">
                                <img :src="require('@/assets/images/Kontyolt nyeregteto.png')" v-if="houses[1].show == 1">
                            <p class="text-uppercase font-italic">KONTYOLT NYEREGTETŐ</p>
                        </b-col>
                        <b-col col lg="4" class="pointer pb-3">
                                <img :src="require('@/assets/images/L nyeregteto_gray.png')"  v-if="houses[2].show == 0" @click="changeData(2)">
                                <img :src="require('@/assets/images/L nyeregteto.png')" v-if="houses[2].show == 1">
                            <p class="text-uppercase font-italic">L NYEREGTETŐ</p>
                        </b-col>
                        <b-col col lg="4" class="pointer">
                                <img :src="require('@/assets/images/Nyeregteto_gray.png')"  v-if="houses[3].show == 0" @click="changeData(3)">
                                <img :src="require('@/assets/images/Nyeregteto.png')" v-if="houses[3].show == 1">
                            <p class="text-uppercase font-italic">NYEREGTETŐ</p>
                        </b-col>
                        <b-col col lg="4" class="pointer">
                                <img :src="require('@/assets/images/Osszetett kontyolt nyeregteto_gray.png')"  v-if="houses[4].show == 0" @click="changeData(4)">
                                <img :src="require('@/assets/images/Osszetett kontyolt nyeregteto.png')" v-if="houses[4].show == 1">
                            <p class="text-uppercase font-italic">Összetett kontyolt nyeregtető</p>
                        </b-col>
                        <b-col col lg="4" class="pointer">
                                <img :src="require('@/assets/images/Osszetett nyeregteto_gray.png')"  v-if="houses[5].show == 0" @click="changeData(5)">
                                <img :src="require('@/assets/images/Osszetett nyeregteto.png')" v-if="houses[5].show == 1">
                            <p class="text-uppercase font-italic">ÖSSZETETT NYEREGTETŐ</p>
                        </b-col>
                     </b-row>
       </b-col>
       <b-col col lg="8" class="mt-3">
         <h5 class="font-weight-bold text-uppercase mb-5">2. Adja meg a tervezéshez szükséges adatokat</h5>
         <b-row>
           <b-col col lg="7">
            <span class="text-uppercase">2.1 Tetőhosszúság (méter)</span>
              <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_felnyeregteto_szines.png')" v-if="houses[0].show == 1">
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_kontyolt_nyeregteto_szines.png')" v-if="houses[1].show == 1"> 
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_l_nyeregteto_szines.png')" v-if="houses[2].show == 1"> 
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_nyeregteto_szines.png')" v-if="houses[3].show == 1"> 
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_osszetett_kontyolt_nyeregteto_szines.png')" v-if="houses[4].show == 1"> 
                           <img class="w-75" :src="require('@/assets/images/ereszkalkulator_haz_osszetett_nyeregteto_szines.png')" v-if="houses[5].show == 1"> 
          </b-col>
          <b-col col lg="5">     
                <b-form-input class="budafer-input" type="number" placeholder="A: (méter)" step="0.1" v-if="forms > 0" min="1" max="100" v-model="result.inputs[0]" :required="forms > 0 ? true : false"></b-form-input>
                <b-form-input class="budafer-input" type="number" placeholder="B: (méter)" step="0.1" v-if="forms > 1" min="1" max="100" v-model="result.inputs[1]" :required="forms > 1 ? true : false"></b-form-input>
                <b-form-input class="budafer-input" type="number" placeholder="C: (méter)" step="0.1" v-if="forms > 2" min="1" max="100" v-model="result.inputs[2]" :required="forms > 2 ? true : false"></b-form-input>
                <b-form-input class="budafer-input" type="number" placeholder="D: (méter)" step="0.1" v-if="forms > 3" min="1" max="100" v-model="result.inputs[3]" :required="forms > 3 ? true : false"></b-form-input>
                <b-form-input class="budafer-input" type="number" placeholder="E: (méter)" step="0.1" v-if="forms > 4" min="1" max="100" v-model="result.inputs[4]" :required="forms > 4 ? true : false"></b-form-input>
          </b-col> 
          </b-row>
       </b-col>
      </b-row>
       <b-row class="mt-5">
        <b-col col lg="4">
          <p class="text-uppercase">2.2 TETŐMAGASSÁG (méter)</p>
          <b-row>
            <b-col col lg="6">
              <img class="w-100" src="@/assets/images/ereszkalkulator_22_tetomagassag.png">
            </b-col>
            <b-col col lg="6">
              
            <b-form-input class="budafer-input" type="number" placeholder="A: (méter)" v-model="result.inputs[5]" step="0.1" min="1" max="100" required></b-form-input>
            <b-form-input class="budafer-input" type="number" placeholder="B: (méter)" v-model="result.inputs[6]" step="0.1" min="1" max="100" required></b-form-input>
          
            </b-col>
          </b-row>
        </b-col>
        <b-col col lg="4">
          <p class="text-uppercase">2.3 Ereszrendszer Tulajdonságai</p>
          <b-row>
            <b-col col lg="6">
              <img class="w-100" src="@/assets/images/ereszkalkulator_23_eresztulajdonsag.png">
            </b-col>
            <b-col col lg="6">
                <b-form-select class="budafer-input" v-model="result.selected[1]" :options="options[1]" required></b-form-select>  
            </b-col>
          </b-row>
        </b-col>
        <b-col col lg="4">
          <p class="text-uppercase">2.4 KÖNYÖK TÍPUSA</p>
          <b-row>
            <b-col col lg="6">
              <img class="w-50 pointer" v-if="(result.selected[2] == null || result.selected[2] == 'dupla')  "  @click="setSelected(2,'szimpla')" src="@/assets/images/ereszkalkulator_24_konyoktipusa_bal_konyok_szurke.png">
              <img class="w-50 pointer" v-if="result.selected[2] == 'szimpla'" @click="setSelected(2,'szimpla')" src="@/assets/images/ereszkalkulator_24_konyoktipusa_bal_konyok_fekete.png">
              <img class="w-50 pointer" v-if="(result.selected[2] == null || result.selected[2] == 'szimpla')  " @click="setSelected(2,'dupla')" src="@/assets/images/ereszkalkulator_24_konyoktipusa_jobb_konyok_szurke.png">
              <img class="w-50 pointer" v-if="result.selected[2] == 'dupla'" @click="setSelected(2,'dupla')" src="@/assets/images/ereszkalkulator_24_konyoktipusa_jobb_konyok_fekete.png">
            </b-col>
            <b-col col lg="6">
              
            <b-form-select class="budafer-input" v-model.lazy="result.selected[2]" :options="options[2]" required></b-form-select>  
          
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mb-5 mt-5">
        <b-col col lg="4">
          <p class="text-uppercase">2.5 BILINCS MÉRETE</p>
          <b-row>
            <b-col col lg="6">
              <img class="w-100" src="@/assets/images/ereszkalkulator_25_bilics_merete_rovid_fekete_v2.png">
            </b-col>
            <b-col col lg="6">
                <b-form-select class="budafer-input" v-model="result.selected[3]" :options="options[3]" required></b-form-select>  
            </b-col>
          </b-row>
        </b-col>
        <b-col col lg="4">
          <p class="text-uppercase">2.6 KIVITEL</p>
           <b-row>
            <b-col col lg="6">
              <img class="w-100 pointer" v-if="(result.selected[4] == null || result.selected[4] == 'Belső peremes')  " @click="setSelected(4,'Külső peremes')" src="@/assets/images/ereszkalkulator_26_minoseg_standard_szurke.png">
              <img class="w-100 pointer" v-if="result.selected[4] == 'Külső peremes'" @click="setSelected(4,'Külső peremes')" src="@/assets/images/ereszkalkulator_26_minoseg_standard_fekete.png">
              <img class="w-100 pointer" v-if="(result.selected[4] == null || result.selected[4] == 'Külső peremes')  " @click="setSelected(4,'Belső peremes')" src="@/assets/images/ereszkalkulator_26_minoseg_premium_szurke.png">
              <img class="w-100 pointer" v-if="result.selected[4] == 'Belső peremes'" @click="setSelected(4,'Belső peremes')"  src="@/assets/images/ereszkalkulator_26_minoseg_premium_fekete.png">
            </b-col>
            <b-col col lg="6">
              
                <b-form-select class="budafer-input" v-model="result.selected[4]" :options="options[4]" required></b-form-select>  
            
            </b-col>
          </b-row>
        </b-col>
        <b-col col lg="4">
          <p class="text-uppercase">2.7 SZÍN</p>
          <b-row class="color-image">
            <b-col col lg="6">
            <b-row><b-col col lg="12"><img class="w-100 pointer" :class="result.selected[5]=='Külső peremes'  ? '' : 'non-selected'" src="@/assets/images/colors/standard.png"  @click="setSelected(5,'Külső peremes')"></b-col></b-row>
            <b-row class="mt-1 image-row">
              <b-col class="pr-0"><img class="w-100 pointer" :class="result.selected[5]=='8003' ? '' : 'non-selected'" src="@/assets/images/colors/8003.png" @click="setSelected(5,'8003')"></b-col>
              <b-col class="pl-1 pr-1"><img class="w-100 pointer" :class="result.selected[5]=='3009' ? '' : 'non-selected'" src="@/assets/images/colors/3009.png" @click="setSelected(5,'3009')"></b-col>
              <b-col class="pl-0"><img class="w-100 pointer" :class="result.selected[5]=='3016' ? '' : 'non-selected'" src="@/assets/images/colors/3016.png" @click="setSelected(5,'3016')"></b-col>
            </b-row>
             <b-row class="mt-1 image-row">
              <b-col class="pr-0"><img class="w-100 pointer" :class="result.selected[5]=='8011' ? '' : 'non-selected'" src="@/assets/images/colors/8011.png" @click="setSelected(5,'8011')"></b-col>
              <b-col class="pl-1 pr-1"><img class="w-100 pointer" :class="result.selected[5]=='8004' ? '' : 'non-selected'" src="@/assets/images/colors/8004.png" @click="setSelected(5,'8004')"></b-col>
              <b-col class="pl-0"><img class="w-100 pointer" :class="result.selected[5]=='7016' ? '' : 'non-selected'" src="@/assets/images/colors/7016.png" @click="setSelected(5,'7016')"></b-col>
            </b-row>
             <b-row class="mt-1 image-row">
              <b-col class="pr-0"><img class="w-100 pointer" :class="result.selected[5]=='8017' ? '' : 'non-selected'" src="@/assets/images/colors/8017.png" @click="setSelected(5,'8017')"></b-col>
              <b-col class="pl-1 pr-1"><img class="w-100 pointer" :class="result.selected[5]=='9007' ? '' : 'non-selected'" src="@/assets/images/colors/9007.png" @click="setSelected(5,'9007')"></b-col>
              <b-col class="pl-0"><img class="w-100 pointer" :class="result.selected[5]=='9010' ? '' : 'non-selected'" src="@/assets/images/colors/9010.png" @click="setSelected(5,'9010')"></b-col>
            </b-row>
            </b-col>
            <b-col col lg="6" class="color-selector">
              
                <b-form-select class="budafer-input" v-model="result.selected[5]" :options="options[5]" required @change="Calculate()"></b-form-select>  
            
            </b-col>
            <b-col class="text-center">
                    <button @click="Calculate()" class="bf-button-blue-2" type="button">Kalkuláció</button>
                </b-col>
          </b-row>
        </b-col>
      </b-row>
              <b-row>
                  <h2 class="w-100 text-uppercase mb-5">A kalkuláció eredménye</h2>
              </b-row>
              <b-row>
        <b-col col lg="3">
        </b-col>
        <b-col col lg="6" class="table-responsive">
             <table class="table table-bordered text-center">
            <thead>
            <tr>
                <th scope="col">Megnevez&eacute;s</th>
                <th scope="col">Megadott adat</th>
            </tr>
            </thead>
            <tbody class="calculated">
            <tr>
                <td>Tetőméretek (A)</td>
                <td>{{this.result.inputs[0]}}</td>
            </tr>
            <tr>
                <td>Tetőméretek (B)</td>
                <td>{{this.result.inputs[1]}}</td>
            </tr>
            <tr>
                <td>Tetőméretek (C)</td>
                <td>{{this.result.inputs[2]}}</td>
            </tr>
            <tr>
                <td>Tetőméretek (D)</td>
                <td>{{this.result.inputs[3]}}</td>
            </tr>
            <tr>
                <td>Tetőméretek (E)</td>
                <td>{{this.result.inputs[4]}}</td>
            </tr>
            <tr>
                <td>Tetőmagasság (A)</td>
                <td>{{this.result.inputs[5]}}</td>
            </tr>
            <tr>
                <td>Tetőmagasság (B)</td>
                <td>{{this.result.inputs[6]}}</td>
            </tr>
            <tr>
                <td>Ereszrendszer</td>
                <td>normál</td>
            </tr>
            <tr>
                <td>Ereszrendszer rendszerátmérő</td>
                <td>{{this.result.selected[1]}}</td>
            </tr>
            <tr>
                <td>Könyök</td>
                <td>{{this.result.selected[2]}}</td>
            </tr>
            <tr>
                <td>Bilincs</td>
                <td>{{this.result.selected[3]}}</td>
            </tr>
            <tr>
                <td>Kivitel</td>
                <td>{{this.result.selected[4]}}</td>
            </tr>
            <tr>
                <td>Szín</td>
                <td>{{this.result.selected[5]}}</td>
            </tr>
            </tbody>
            </table>
        </b-col>
        <b-col col lg="6" class="table-responsive">
            <table class="table table-bordered text-center">
                <thead>
                    <tr>
                    <th scope="col">Megnevezés</th>
                    <th scope="col">Mennyiség</th>
                    <th scope="col">Mértékegység</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Csatorna</td>
                        <td>{{calculated.csatorna}}</td>
                        <td>m</td>
                    </tr>
                    <tr>
                        <td>Csatornatartó</td>
                        <td>{{calculated.kampo}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Zárt vég</td>
                        <td>{{calculated.zart_veg}}</td>
                        <td>db</td>
                    </tr>
                    <tr>
                        <td>Szeglet</td>
                        <td>{{calculated.sarok}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Zárt vég kifolyóval</td>
                        <td>{{calculated.kifolyo}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Betorkolló</td>
                        <td>{{calculated.betorkollo}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Lefolyó</td>
                        <td>{{calculated.lefolyo}}</td>
                        <td>m</td>
                    </tr>
                     <tr>
                        <td>Könyök</td>
                        <td>{{calculated.konyok}}</td>
                        <td>db</td>
                    </tr>
                     <tr>
                        <td>Bilincs</td>
                        <td>{{calculated.bilincs}}</td>
                        <td>db</td>
                    </tr>
                </tbody>
            </table>
        </b-col>
    </b-row>
              <b-row>
                    <b-col col lg="3"></b-col>
                <b-col class="text-center">
                    <button v-b-modal.modal-1 class="bf-button-red" type="button">Ajánlatot kérek</button>
                  </b-col>
                    <b-col col lg="3"></b-col>
              </b-row>
              <b-row>
                   <b-col col lg="4"></b-col>
                    <b-col class="text-center info-box" l col lg="4">
                       <p>Elakadt, segítségre van szüksége?<br>Vegye fel velünk a kapcsolatot!</p>
                       <hr>
                       <p><a href="mail:info@budafer.hu">info@budafer.hu</a> • 06-1-99-888-99</p>
                    </b-col>
                     <b-col col lg="4"></b-col>
              </b-row>
    </b-container>
   </b-form>
</div>
</template>
<script>
import API from '../api'
export default {
    props: ['page','prodClick'],
    created() {
        this.setup();
    },
    watch: { 
        prodClick: function() {
          this.setup();
        }
      },
    data() {
        return {
            result:{
                selected:['0,45',null,null,null,null,null],
                inputs: ['','','','','','','']
            },
            selected: false,
            slide: 0,
            sliding: false,
            forms:-1,
            options: [
                [
                { value: '0,45', text: 'Normál', selected: '0,45'}
                ],
                [
                { value: null, text: 'Rendszerátmérő',disabled: true },
                { value: '25', text: '25 cm' },
                { value: '33', text: '33 cm' },
                { value: '40', text: '40 cm' }
                ],
                [
                { value: null, text: 'Típus',disabled: true },
                { value: 'szimpla', text: 'könyök' },
                { value: 'dupla', text: 'hattyúnyak' }
                ],
                [
                { value: null, text: 'Szárhossz',disabled: true },
                { value: '120', text: '120 mm' },
                { value: '150', text: '150 mm' },
                { value: '180', text: '180 mm' },
                { value: '250', text: '250 mm' },
                { value: '300', text: '300 mm' }
                ],
                [
                { value: null, text: 'Kivitel',disabled: true },
                { value: 'Külső peremes', text: 'Külső peremes' },
                { value: 'Belső peremes', text: 'Belső peremes' }
                ],
                [
                    { value: null, text: 'Szín kiválasztása',disabled: true },
                    { value: 'horganyzott acél', text: 'Horganyzott' },
                    { value: '8003', text: 'RAL 8003 Tűzbarna' },
                    { value: '3009', text: 'RAL 3009 Bordó' },
                    { value: '3016', text: 'RAL 3016 Téglavörös' },
                    { value: '8011', text: 'RAL 8011 Barna' },
                    { value: '8004', text: 'RAL 8004 Cserépvörös' },
                    { value: '7016', text: 'RAL 7016 Antacit' },
                    { value: '8017', text: 'RAL 8017 Rézbarna' },
                    { value: '9007', text: 'RAL 9007 Ezüst' },
                    { value: '9010', text: 'RAL 9010 Fehér' }
                ],
            ],
             houses:[
            {
              id:0,
              side:1,
              selected:0,
              show:0,
              img:"ereszkalkulator_haz_felnyeregteto_szines.png",
              name:"FÉLNYEREGTETŐ"
            },
             {
              id:1,
              side:2,
              selected:0,
              show:0,
              img:"ereszkalkulator_haz_kontyolt_nyeregteto_szines.png",
              name:"KONTYOLT NYEREGTETŐ"
            },
            {
               id:2,
              side:4,
              selected:0,
              show:0,
              img:"ereszkalkulator_haz_l_nyeregteto_szines.png",
              name:"L NYEREGTETŐ"
            },
            {
               id:3,
              side:1,
              selected:0,
              show:0,
              img:"ereszkalkulator_haz_nyeregteto_szines.png",
              name:"NYEREGTETŐ"
            },
            {
               id:4,
              side:5,
              selected:0,
              show:0,
              img:"ereszkalkulator_haz_osszetett_kontyolt_nyeregteto_szines.png",
              name:"Összetett kontyolt nyeregtető"
            },
             {
               id:5,
              side:4,
              selected:0,
              show:0,
              img:"ereszkalkulator_haz_osszetett_nyeregteto_szines.png",
              name:"ÖSSZETETT NYEREGTETŐ"
            },
          ],
          calculated:{
                csatorna:0,
                kampo:0,
                zart_veg:0,
                sarok:0,
                kifolyo:0,
                betorkollo:0,
                lefolyo:0,
                konyok:0,
                bilincs:0
            },
            form:{
                name:'',
                company:'',
                email:'',
                phone:'',
                message:'',
                calculated:'',
                original:'',
                newsletter:'',
                transport:''
            },
        }
    },
    methods: {
        getImgUrl(pic) {
                return require('@/assets/images/'+pic)
            },
        next() {
            let error = false;
            if(this.slide == 2) error = this.validatefirst();
            if(this.slide == 3) error = this.validatesecound();
            if(!error)
            {
                this.$refs.Carousel.next();
                if(this.slide == 8) this.Calculate();
            }
            
        
        },
        prev(){
            this.$refs.Carousel.prev()
        },
        setup(){
            if(this.prodClick != -1)
            {
                this.slide = 2;
                this.changeData(this.prodClick);
                this.$emit('update:prodClick', -1);
            }
        },
        changeData(id){
            this.selected = true;
            this.houses.forEach(element => {
            if(element.id == id)
            {
                element.show = 1;
                this.forms=element.side;
            }else
            {
                element.show = 0;
            }
            });
        },
        setSelected(id,content){
            this.result.selected[id] = content;
        },
        validatefirst()
        {
                if(this.forms > 0 && this.$refs.housea.checkValidity() == false) return true;
                if(this.forms > 1 && this.$refs.houseb.checkValidity() == false) return true;
                if(this.forms > 2 && this.$refs.housec.checkValidity() == false) return true;
                if(this.forms > 3 && this.$refs.housed.checkValidity() == false) return true;
                if(this.forms > 4 && this.$refs.housee.checkValidity() == false) return true;
                return false;
        },
        validatesecound()
        {
            if(this.$refs.roufa.checkValidity() == false) return true;
            if(this.$refs.roufb.checkValidity() == false) return true;
                return false;
        },
         Calculate()
        {
             let name = "";
             this.houses.forEach(element => {
                if(element.show == 1) name = 'calc'+(element.id+1);
             });
             this[name]();
        },
        calc1()
        {
            let vegek = 2;
            let csatorna_total = this.result.inputs[0];
            this.calculated.kampo = Math.ceil(csatorna_total / 0.8);
            this.calculated.zart_veg = (this.result.inputs[0] > 15) ? 0 : 1;
            this.calculated.sarok = 0;
            this.calculated.kifolyo = vegek - this.calculated.zart_veg;
            this.calculated.betorkollo = Math.ceil((this.result.inputs[0] < 31) ? 0 : csatorna_total / 15 - 2);
            let lefolyo = this.calculated.kifolyo + this.calculated.betorkollo;
            this.calculated.lefolyo = lefolyo * this.result.inputs[6];

            this.calculated.csatorna = csatorna_total - vegek;
            if(this.result.selected[2] == 'szimpla')
            {
                this.calculated.konyok = Math.ceil(lefolyo)*2;
            }else
            {
                this.calculated.konyok = Math.ceil(lefolyo);
            }
            this.calculated.bilincs = this.calculated.lefolyo;
        },
        calc2()
        {
            let vegek = 0;
            let csatorna_total = (+this.result.inputs[0] + +this.result.inputs[1]) *2;
            this.calculated.kampo = Math.ceil(csatorna_total / 0.8);
            this.calculated.zart_veg = 0;
            this.calculated.sarok = 4;
            this.calculated.kifolyo = 0;
            this.calculated.betorkollo = Math.ceil(csatorna_total / 15);
            let lefolyo = this.calculated.kifolyo + this.calculated.betorkollo;
            this.calculated.lefolyo = lefolyo * this.result.inputs[6];
             
            this.calculated.csatorna = csatorna_total - vegek;
            if(this.result.selected[2] == 'szimpla')
            {
                this.calculated.konyok = Math.ceil(lefolyo)*2;
            }else
            {
                this.calculated.konyok = Math.ceil(lefolyo);
            }
            this.calculated.bilincs = this.calculated.lefolyo; 
        },
        calc3()
        {
             let vegek = 4;
            let csatorna_total = +this.result.inputs[0] + +this.result.inputs[1] + +this.result.inputs[2] + +this.result.inputs[3];
            this.calculated.kampo = Math.ceil(csatorna_total / 0.8);
            this.calculated.zart_veg = (((+this.result.inputs[2] + +this.result.inputs[3]) > 15) ? 0 : 1) + (((+this.result.inputs[0] + +this.result.inputs[1]) > 15) ? 0 : 1);
            this.calculated.sarok = 2;
            this.calculated.kifolyo = vegek - this.calculated.zart_veg;
            this.calculated.betorkollo = Math.ceil((((+this.result.inputs[2] + +this.result.inputs[3]) < 31) ? 0 : (+this.result.inputs[2] + +this.result.inputs[3]) / 15 - 2) + (((+this.result.inputs[0] + +this.result.inputs[1]) < 31) ? 0 : (+this.result.inputs[0] + +this.result.inputs[1]) - 2));
            let lefolyo = this.calculated.kifolyo + this.calculated.betorkollo;
            this.calculated.lefolyo = lefolyo * this.result.inputs[6];

            this.calculated.csatorna = csatorna_total - vegek;
            if(this.result.selected[2] == 'szimpla')
            {
                this.calculated.konyok = Math.ceil(lefolyo)*2;
            }else
            {
                this.calculated.konyok = Math.ceil(lefolyo);
            }
            this.calculated.bilincs = this.calculated.lefolyo; 
        },
        calc4()
        {
            let vegek = 4;
            let csatorna_total = this.result.inputs[0]*2;
            this.calculated.kampo = Math.ceil(csatorna_total / 0.8);
            this.calculated.zart_veg = (this.result.inputs[0] > 15) ? 0 : 2;
            this.calculated.sarok = 0;
            this.calculated.kifolyo = vegek - this.calculated.zart_veg;
            this.calculated.betorkollo = ( this.result.inputs[0] < 31) ? 0 : Math.ceil(( this.result.inputs[0] / 15 - 2)) * 2;
            let lefolyo = this.calculated.kifolyo + this.calculated.betorkollo;
            this.calculated.lefolyo = lefolyo * this.result.inputs[6];

            this.calculated.csatorna = csatorna_total - vegek;
            if(this.result.selected[2] == 'szimpla')
            {
                this.calculated.konyok = Math.ceil(lefolyo)*2;
            }else
            {
                this.calculated.konyok = Math.ceil(lefolyo);
            }
            this.calculated.bilincs = this.calculated.lefolyo;
        },
        calc5()
        {
            let vegek = 0;
            let csatorna_total =  +this.result.inputs[4] + (this.result.inputs[3] * 2) + (this.result.inputs[2] * 2) + +this.result.inputs[0] + +this.result.inputs[1] + (this.result.inputs[4] - this.result.inputs[1] - this.result.inputs[0]);
            this.calculated.kampo = Math.ceil(csatorna_total / 0.8);
            this.calculated.zart_veg = 0;
            this.calculated.sarok = 8;
            this.calculated.kifolyo = 0;
            this.calculated.betorkollo = Math.ceil(csatorna_total / 15);
            let lefolyo = this.calculated.kifolyo + this.calculated.betorkollo;
            this.calculated.lefolyo = lefolyo * this.result.inputs[6];

            this.calculated.csatorna = csatorna_total - vegek;
            if(this.result.selected[2] == 'szimpla')
            {
                this.calculated.konyok = Math.ceil(lefolyo)*2;
            }else
            {
                this.calculated.konyok = Math.ceil(lefolyo);
            }
            this.calculated.bilincs = this.calculated.lefolyo;
        },
        calc6()
        {
            let vegek = 6;
            let csatorna_total =  +this.result.inputs[3] + (this.result.inputs[2] * 2) + +this.result.inputs[1] + (+this.result.inputs[3] - +this.result.inputs[2] - +this.result.inputs[1]);
            this.calculated.kampo = Math.ceil(csatorna_total / 0.8);
            this.calculated.zart_veg = ((this.result.inputs[3] > 15) ? 0 : 1) + (((+this.result.inputs[2] + this.result.inputs[1]) > 15) ? 0 : 1) + (((this.result.inputs[3] - this.result.inputs[1] - this.result.inputs[0] + +this.result.inputs[2]) > 15) ? 0 : 1);
            this.calculated.sarok = 2;
            this.calculated.kifolyo = vegek - this.calculated.zart_veg;
            this.calculated.betorkollo =Math.ceil(((this.result.inputs[3] < 31) ? 0 : this.result.inputs[3] / 15 - 2) + (((+this.result.inputs[2] + +this.result.inputs[1]) < 31) ? 0 : (+this.result.inputs[2] + +this.result.inputs[1]) / 15 - 2) + (((this.result.inputs[3] - this.result.inputs[1] - this.result.inputs[0] + +this.result.inputs[3]) < 31) ? 0 : (this.result.inputs[3] - this.result.inputs[1] - this.result.inputs[0] + +this.result.inputs[2]) / 15 - 2));
            let lefolyo = this.calculated.kifolyo + this.calculated.betorkollo;
            this.calculated.lefolyo = lefolyo * this.result.inputs[6];

            this.calculated.csatorna = csatorna_total - vegek;
            if(this.result.selected[2] == 'szimpla')
            {
                this.calculated.konyok = Math.ceil(lefolyo)*2;
            }else
            {
                this.calculated.konyok = Math.ceil(lefolyo);
            }
            this.calculated.bilincs = this.calculated.lefolyo;
        },
         submitProduct(){
            this.form.calculated=JSON.stringify(this.calculated);
            this.form.original = JSON.stringify(this.result);
            console.log(this.form);
             API.post('sendemail', this.form),
            this.hideModal();
        },
        hideModal() {
            this.$refs['contact-modal'].hide();
        }
    }
}
</script>
<style>
.carousel-caption{
    color:#000 !important;
}
.last-step .carousel-caption{
    height: 100%;
    overflow-y: auto;
    padding-top:30px;
}
.pointer{
    cursor: pointer;
}
.startbutton{
    background-color: #bb4830 !important;
    color:white !important;
}
.startbutton:hover{
    background-color: #bb4850 !important;
}
.arrow-next{
    margin-top:10rem;
    background-color: #bb4830;
    border-radius: 1000rem;
    height:2rem;
    width:2rem;
    padding-top:0.2rem;

}
.arrow {
  border: solid white;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
}

.right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}
.left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}
.budafer-input{
    margin-bottom: 1.5rem;
    border:solid black 1px  !important;
    border-radius:2rem  !important;
    height:3rem  !important;
    font-weight: bold  !important;
  }
.non-selected{
  opacity: 0.6;
}
.neg-margin{
    margin-top: -50%;
}
table{
    border: solid 2px #cacaca;
}
 table th{
        background-color: #cacaca;
        text-transform: uppercase;
        padding:3px 0px;
    }
    table td{
        background-color: #ffffff;
        padding:0.2rem 0 !important;

    }
    .bf-button-blue-2 {
        border-radius: 1rem;
        padding: 0.5rem 2.5rem;
        background-color: #465da9;
        color: #fff;
        font-size: 0.8rem;
        font-weight: 700;
        border: 0;
    }
     .bf-button-red {
        border-radius: 1rem;
        padding: 0.5rem 2rem;
        background-color: #bb4830;
        color: #fff;
        font-size: 0.8rem;
        font-weight: 700;
        border: 0;
    }
    .bf-button-gary {
        border-radius: 20px;
        padding: 3px 15px;
        background-color: #000;
        color: #fff;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        border: 0;
        width: 100%;
    }
     .bf-contact .textfield, .bf-contact textarea {
        width: 100%;
        border-radius: 20px;
        padding: 5px 10px;
        border: 1px solid;
        margin-bottom: 10px;
    }
    .bf-contact textarea {
        resize: none;
        height: 270px;
    }
    .bf-contact button {
        margin-top: 30px;
        border-radius: 20px;
        padding: 3px 15px;
        background-color: #ffa21c;
        color: #000;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        border: 0;
    }
    .info-box{
        background-color: white;
        border-radius: 1rem;
        color:#465da9;
        text-transform: uppercase;
        padding-top:1rem;
        margin-top:4rem;
    }
    .info-box hr{
         border: 1px solid #bb4830;
    }

@media (-webkit-min-device-pixel-ratio: 1.25) {
    .arrow-next {
            padding-top: 0.4rem !important;
    }
    .bf-button-red {
        padding: 0.5rem 1.8rem;
    }
}
@media (max-width:576px) {
    .col{
      flex-basis: auto !important;
      text-align: center;
    }
    .color-image
    {
       flex-basis: 0;
    }
    .color-image div{
      padding:0 !important;
    }
    .color-image img{
      width:94% !important;
    }
    .image-row img{
      width:100px !important;
    }
    h1{
       font-size:2rem;
       text-align: center;
       width:100%;
    }
    .image-row .col{
      width:33% !important;
    }
    .color-selector{
      margin-top:50px;
    }
    .sendbutton{
      float:inherit !important;
    }
}
</style>