<template>
  <div id="app">
  <div class="header w-100 py-3 desktoponly">
    <b-container>
         <b-row>
           <b-col col lg="3">
              <img @click="page = 'main'" class="w-100" :src="require('@/assets/images/logo.png')">
           </b-col>
           <b-col col lg="8">
             <b-row>
               <b-col class="text-center" col lg="2">
                 <a href="javascript:void(0)" @click="clickMenu(0)">
                  <img class="w-75" :src="require('@/assets/images/Felnyeregteto.png')">
                  <p class="text-uppercase font-italic">FÉLNYEREGTETŐ</p>
                  </a>
               </b-col>
               <b-col class="text-center" col lg="2">
                 <a href="javascript:void(0)" @click="clickMenu(1)">
                  <img class="w-75" :src="require('@/assets/images/Kontyolt nyeregteto.png')">
                  <p class="text-uppercase font-italic">KONTYOLT NYEREGTETŐ</p>
                  </a>
               </b-col>
               <b-col class="text-center" col lg="2">
                 <a href="javascript:void(0)" @click="clickMenu(2)">
                  <img class="w-75" :src="require('@/assets/images/L nyeregteto.png')">
                  <p class="text-uppercase font-italic">L NYEREGTETŐ</p>
                  </a>
               </b-col>
               <b-col class="text-center" col lg="2">
                 <a href="javascript:void(0)" @click="clickMenu(3)">
                  <img class="w-75" :src="require('@/assets/images/Nyeregteto.png')">
                  <p class="text-uppercase font-italic">NYEREGTETŐ</p>
                  </a>
               </b-col>
               <b-col class="text-center" col lg="2">
                 <a href="javascript:void(0)" @click="clickMenu(4)">
                  <img class="w-75" :src="require('@/assets/images/Osszetett kontyolt nyeregteto.png')">
                  <p class="text-uppercase font-italic">Összetett kontyolt nyeregtető</p>
                  </a>
               </b-col>
               <b-col class="text-center" col lg="2">
                 <a href="javascript:void(0)" @click="clickMenu(5)">
                  <img class="w-75" :src="require('@/assets/images/Osszetett nyeregteto.png')">
                  <p class="text-uppercase font-italic">ÖSSZETETT NYEREGTETŐ</p>
                  </a>
               </b-col>
             </b-row>
           </b-col>
           <b-col col lg="1">
                <a href="http://gyarto.budafer.hu" target="_blank"><div class="budafer py-1 mb-1 pl-1"><img :src="require('@/assets/images/budafer-logo.png')"></div></a>
                <div class="phone py-1 mb-1  pl-1" @click="page = 'contact'"><img :src="require('@/assets/images/phone.png')"></div>
           </b-col>
         </b-row>
    </b-container>
  </div>
  <div class="mobileonly">
    <b-navbar toggleable type="light" variant="white">
         <b-navbar-brand href="#" @click="page = 'main'" class="w-75">
          <img :src="require('@/assets/images/logo.png')" class="w-50">
        </b-navbar-brand>

        <b-navbar-toggle target="navbar-toggle-collapse">
          <template #default="{ expanded }">
            <b-icon v-if="expanded" icon="list"></b-icon>
            <b-icon v-else icon="list"></b-icon>
          </template>
        </b-navbar-toggle>

        <b-collapse id="navbar-toggle-collapse" is-nav>
          <b-navbar-nav class="ml-auto text-center">
              <b-nav-item href="javascript:void(0)" @click="clickMenu(0)">
                FÉLNYEREGTETŐ
              </b-nav-item>
              <b-nav-item href="javascript:void(0)" @click="clickMenu(1)">
                KONTYOLT NYEREGTETŐ
              </b-nav-item>
              <b-nav-item href="javascript:void(0)" @click="clickMenu(2)">
                L NYEREGTETŐ
              </b-nav-item>
              <b-nav-item href="javascript:void(0)" @click="clickMenu(3)">
                NYEREGTETŐ
              </b-nav-item>
              <b-nav-item class="text-uppercase" href="javascript:void(0)" @click="clickMenu(4)">
                Összetett kontyolt nyeregtető
              </b-nav-item>
              <b-nav-item class="text-uppercase" href="javascript:void(0)" @click="clickMenu(5)">
                ÖSSZETETT NYEREGTETŐ
              </b-nav-item>
          </b-navbar-nav>
          <hr>
          <b-navbar-nav class="ml-auto text-center">
            <b-nav-item href="http://budafer.hu" target="_blank">Budafer</b-nav-item>
            <b-nav-item href="javascript:void(0)" @click="page = 'contact'">Kapcsolat</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
    </b-navbar>
  </div>
   <div class="main"> 
    <Main v-if="page == 'main'"
      :page.sync = "page"
      :prodClick.sync="prodClick"
      >
    </Main>
    <Contact v-if="page == 'contact'">
    </Contact>
    <Impressum v-if="page == 'impressum'">
    </Impressum>
   </div>
    <div class="footer">
      <div class="crosslink desktoponly">
        <p><a class="white" href="https://acelkalkulator.hu" target="_blank"><span>Próbálja ki</span><br>acélkalkulátorunkat is!</a></p>
      </div>
      <ul>
        <li>Budafer Gyártó és Kereskedő Kft. {{new Date().getFullYear()}}</li>
        <li class="li-sec link" @click="page = 'impressum'"> * Impresszum</li>
        <li class="li-sec"> * Adatvédelmi nyilatkozat</li>
      </ul>
      </div>
  </div>
</template>

<script>
import Main from './components/Main.vue'
import Contact from './components/Contact.vue'
import Impressum from './components/Impressum.vue'

export default {
  name: 'App',
  components: {
     Main,
    Contact,
    Impressum
  },
  data() {
    return{
      page:'main',
      prodClick:-1
    }
  },
  methods: {
     getImgUrl(pic) {
       return require('@/assets/images/'+pic)
       },
        clickMenu(id){
        this.prodClick = id;
        this.page = "main";
     }
  }
}
</script>

<style>
body{
  overflow-y: hidden;
   background-color: #e1e1e1 !important;
   color:black;
}
 #app{
    font-size: 1rem;
    color: black;
    font-family: 'Roboto', sans-serif !important;
  }
.header{
  border-top: solid 0.4rem #707070;
  font-size: 0.6rem;
  background-color: white;
}
.header p{
  padding: 0;
  margin:0;
}
.header a{
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.header a:hover{
  text-decoration: none;
  color:#465f9a;
}
.main{
  height: 80vh;
  overflow-y: auto;
}
.footer{
  position: absolute;
  bottom:0;
  width:100%;
  color:white;
  z-index: 1000;
}
.footer .crosslink{
  background-color: #707070;
  float:right;
  padding:1rem 1rem 1rem 2rem;
  border-radius: 3rem 0 0 0;
  text-align: center;
  font-size: 1rem;
  cursor:pointer;

}
.footer .crosslink span{
  font-size: 1.2rem;
}
.footer .crosslink p{
  padding:0;
  margin:0;
}
.footer ul{
  text-align: center;
  float:left;
  width:100%;
  background-color: #465f9a;
  border-top: solid 0.3rem #707070;
  margin-bottom:0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.footer ul li{
  display: inline-block;
}
.footer ul .li-sec{
  padding-left:0.4rem;
}
.budafer{
  background-color: #465fa9;
  cursor: pointer;
}
.budafer:hover{
  margin-left:-1rem;
}
.phone{
  background-color: #bb4830;
  cursor: pointer;
}
.phone:hover{
  margin-left:-1rem;
} 
.search:hover{
  margin-left:-1rem;
}
.white{
    color:white !important;
}
.link{
  cursor: pointer;
}
.link:hover{
  text-decoration: underline;
}
.mobileonly{
        display:none !important;
      }
@media (-webkit-min-device-pixel-ratio: 1.25) {
 #app{
        font-size: 0.8rem !important;
      }
    h1{
      font-size: 2rem !important;
    }
    p{
      margin-bottom: 0.5rem !important;
    }
}
@media (max-width:576px) {
      .desktoponly{
        display: none !important;
      }
      .mobileonly{
        display: block !important;
      }
      h1{
        font-size: 1.6rem !important;
      }
      .main{
        padding-left: 1rem;
        padding-right: 1rem;
      }
      body{
        overflow: auto !important;
      }
      .footer{
        position: relative;
      }
    }
</style> 
