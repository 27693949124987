import axios from 'axios';

export default axios.create({
    baseURL: `https://gyarto.budafer.hu/api/v1/eves/`,
    headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
    },
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
    withCredentials: true
});
