<template>
    <b-container class="contact">
        <b-row>
            <b-col col lg="12">
                <h1 class="py-5 text-uppercase"><b>IMPRESSZUM</b></h1>
            </b-col>
        </b-row>
        <b-row>
            <b-col col lg="6">
                <iframe width="100%" height="500px" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" src="https://maps.google.com/maps?q=Budafer+Hungary+Zrt.&t=&z=13&ie=UTF8&iwloc=&output=embed"></iframe>
            </b-col>
             <b-col col lg="6">
                 <p class="budafer-underline"><b>Szolgáltató adatai</b></p>
                 <p>
                    Szolgáltató: Budafer Hungary Zrt.<br>
                    Cégjegyzékszáma: 13-10-041693<br>
                    Céget nyilvántartó hatóság: Budapest Környéki Törvényszék Cégbírósága<br>
                    Adószáma: 25890413-2-13<br>
                    Székhelye: 2724 Újlengyel, Határ út 12.<br>
                    Fióktelep: 7200 Dombóvár, Köztársaság utca 10.<br>
                    Elérhetősége: office@budafer.hu<br>
                    Weboldal: www.ereszkalkulator.hu<br>
                 </p>
                 <p class="budafer-underline"><b>Tárhelyszolgáltató</b></p>
                 <p>
                    Microsoft Ireland Operations Ltd.<br>
                    Attn: Privacy Officer<br>
                    Carmenhall Road<br>
                    Sandyford, Dublin 18, Ireland<br>
                 </p>
            </b-col>
        </b-row>
        <b-modal ref="contact-modal" id="modal-1" hide-footer title="Kapcsolat">
                <div class="bf-contact">
                    <input class="textfield" type="text" name="name" placeholder="Név:" required=""><br>
                    <input class="textfield" type="text" name="company" placeholder="Cégnév:" required=""><br>
                    <input class="textfield" type="email" name="email" placeholder="Email Cím:" required=""><br>
                    <input class="textfield" type="tel" id="phone" name="phone" placeholder="+36301234567" title="+36301234567, 06301234567" pattern="^\+?(\d{10,11})$" required=""><br>
                    <textarea class="textfield" name="message"></textarea><br>
                    <input type="hidden" name="_token" id="token" value="iCpL3ybf32Xvaqkcqe5Jz35JPfZUxkuuBKspETde">
                    <input type="checkbox" name="newsletter"> Feliratkozom a hírlevélre.<br>
                    <input type="checkbox" required=""> Elolvastam és elfogadom az <a href="//budafer.hu/adatkezelesi-tajekoztato" target="_blank">adatvédelmi nyilatkozatot</a>.<br>
                    <button class="bf-button-yellow" @click="hideModal" type="submit">Üzenet elküldése</button>
                </div>
            </b-modal>
    </b-container>
</template>
<script>
export default {
     methods: {
         hideModal() {
            this.$refs['contact-modal'].hide()
        }
     }
}
</script>
<style scoped>
    .contact{
        text-align: left;
    }
    h1{
        text-align: center;

    }
    a{
        font-weight: normal;
    }
    .budafer-underline{
        font-size: 1.5rem;
        text-decoration: underline;
        text-decoration-color: #465fa9;
        text-decoration-thickness: 3px;
        text-underline-offset:10px;
    }
    .bf-button-blue {
        border-radius: 20px;
        padding: 10px 25px;
        background-color: #465da9;
        color: #fff;
        font-size: 16px;
        font-weight: 700;
        border: 0;
    }
    a{
        text-decoration: none;
        color:black;
    }
    a:hover{
        text-decoration: none;
        color:#465da9;
    }
    .bf-button-gary {
        border-radius: 20px;
        padding: 3px 15px;
        background-color: #000;
        color: #fff;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        border: 0;
        width: 100%;
    }
     .bf-contact .textfield, .bf-contact textarea {
        width: 100%;
        border-radius: 20px;
        padding: 5px 10px;
        border: 1px solid;
        margin-bottom: 10px;
    }
    .bf-contact textarea {
        resize: none;
        height: 270px;
    }
    .bf-contact button {
        margin-top: 30px;
        border-radius: 20px;
        padding: 3px 15px;
        background-color: #ffa21c;
        color: #000;
        font-size: 16px;
        font-style: italic;
        font-weight: 700;
        border: 0;
    }
    @media (max-width:576px) {
    .col{
        flex-basis: auto !important;
    }
}
</style>